import axios from "axios";

export default class Email {
  constructor() {
    this.token = btoa(
      `${process.env.REACT_APP_CLICKSEND_USERNAME}:${process.env.REACT_APP_CLICKSEND_PASSWORD}`
    );
    this.emailEndPoint = process.env.REACT_APP_EMAIL_URL;
    this.userEmailEndPoint = process.env.REACT_APP_USER_EMAIL_URL;
  }

  send = async ({ from, to, subject, body: emailBody, attachments }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const body = {
          to,
          subject,
          body: emailBody,
          attachments,
          from: { name: "Al Asr Madressa", ...from },
        };

        const response = await axios.post(this.emailEndPoint, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Basic ${this.token}`,
          },
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  getFromEmails = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(this.userEmailEndPoint, {
          headers: {
            Authorization: `Basic ${this.token}`,
          },
        });
        const {
          data: {
            data: { data },
          },
        } = response;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };
}
