import {
  Flex,
  Box,
  Image,
  Button,
  Text,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../assests/logo.svg";
import isAuthenticated from "../utils/auth";

const Header = () => {
  const [user, updateUser] = useState(null);
  const [grade, updateGrade] = useState(0);
  const [authenticatedUser, updateAuth] = useState(true);

  useEffect(() => {
    isAuthenticated().then((data) => updateAuth(data));
  }, []);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("smart-alim:user"));
    if (token) {
      updateUser(token);
    } else {
      updateUser({});
    }
  }, []);
  return (
    <Flex p="16px">
      {/* ---Logo--- */}

      <Link to="/">
        <Image src={Logo} w="80px" />
      </Link>

      {/* ---White Space--- */}
      <Box flexGrow={1}></Box>

      {/* ---Left Section--- */}
      {/* Display this section only if the user is logged in */}

      {user && Object.keys(user).length > 0 ? (
        <Flex justifyContent="space-between">
          <Flex justifySelf="flex-end" justifyContent="space-around" alignItems="center " border="1px solid lightgray" padding="8px" borderRadius="12px" backgroundColor="lightgray">
            <FormControl
              mx={8}
              id="grade-in-school"
              isRequired
              display="flex"
              alignItems="center"
              size="small"
            >
              <FormLabel fontSize="small">Grade In School</FormLabel>
              <Select
                name="gradeInSchool"
                value={grade}
                onChange={(e) => updateGrade(e.target.value)}
                fontSize="small"
                w="fit-content"
              >
                <option value="0">Kinder Garden</option>
                <option value="1">Prep</option>
                <option value="2">Year 1</option>
                <option value="3">Year 2</option>
                <option value="4">Year 3</option>
                <option value="5">Year 4</option>
                <option value="6">Year 5</option>
                <option value="7">Year 6</option>
                <option value="8">Year 7</option>
                <option value="9">Year 8</option>
                <option value="10">Year 9</option>
                <option value="11">Year 10</option>
              </Select>
            </FormControl>
            <Button
              color="brand.orange"
              backgroundColor="white"
              as="a"
              href={`${process.env.REACT_APP_ORIGIN}/student/download/report/?class=${grade}`}
              display={authenticatedUser ? "flex" : "none"}
              alignItems="center"
              w="max-content"
              fontSize="smaller"
              _hover={{}}
              whiteSpace="break-spaces"
              textAlign="center"
            >
              Download Student Report
            </Button>
          </Flex>
          <Button
            variant="link"
            onClick={() => {
              localStorage.removeItem("smart-alim:user");
              window.location.href = "/login";
            }}
            mx={8}
          >
            Logout
          </Button>
        </Flex>
      ) : (
        <Text color="brand.orange" fontWeight="semibold">
          Guest User
        </Text>
      )}
    </Flex>
  );
};

export default Header;
